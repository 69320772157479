table {
    padding: 5px;
}

td {
    border-width: 0px;
}

table.table_plain_vs {
    width: 365px;
    background-color: transparent;
    box-shadow: none;
    border-spacing: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
}

.table_vowel {
    width: 365px;
    background-color: transparent;
    box-shadow: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
    padding: 0px;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    text-align: left;
}

td.vsym5 {
    height: 30px;
    width: 50px;
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: none;
    padding: 0px;
    border-width: 0px;
}
.vdescr7 {
    height: 39px;
    width: 70px;
    vertical-align: middle;
    text-align: center;
    border: none;
    padding: 0px;
    background-color: #fff;
    border-width: 0px;
}
.sym_descr_v {
    white-space: nowrap;
    font-weight: normal;
    font-size: 13px;
    font-family: inherit;
    color: rgba(5, 60, 36, 0.33);
    display: block;
    border-color: transparent;
}

td.vdescr {
    height: 50px;
    text-align: left;
    border: none;
    padding: 0px;
    border-width: 1px;
}
td.vdescr5 {
    padding-left: 11px;
    height: 39px;
    width: 50px;
    vertical-align: middle;
    text-align: center;
    border: none;
    padding: 0px;
    border-spacing: 0px;
    border-width: 0px;
}
td.vsym3 {
    height: 30px;
    width: 33px;
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: none;
    padding: 0px;
    border-spacing: 0px;
    border-width: 0px;
}
td.vsym {
    height: 30px;
    width: 10px;
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: none;
    padding: 0px;
    border-spacing: 0px;
    border-width: 0px;
}

.symSR2 {
    cursor: pointer;
    padding-right: 2px;
    text-align: right;
    font-weight: normal;
    font-size: 27px;
    font-family: IPASansS;
    color: rgba(50, 50, 50, 1);
    border-width: 0px;
}

.symSL2 {
    cursor: pointer;
    padding-left: 2px;
    text-align: left;
    font-weight: normal;
    font-size: 27px;
    font-family: IPASansS;
    color: rgba(50, 50, 50, 1);
    border-width: 0px;
}

.symS {
    font-weight: normal;
    cursor: pointer;
    font-size: 27px;
    font-family: IPASansS;
    columns: rgba(50, 50, 50, 1);
}
