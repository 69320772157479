table {
    border-collapse: separate;
    text-indent: initial;
}

td {
    display: table-cell;
    vertical-align: inherit;
    border: solid;
    border-spacing: 0px;
    padding: 0px;
}

.cpmeta_row {
    padding-left: 7px;
    height: 39px;
    width: 84px;
    min-width: 84px;
    max-width: 84px;
    background: linear-gradient(180deg, rgb(3, 19, 13), rgba(4, 38, 28, 0.885));
}

.cpmetaL {
    padding-left: 2px;
    background: linear-gradient(180deg, rgb(3, 19, 13), rgba(4, 38, 28, 0.885));
    background-repeat: repeat-x;
    height: 39px;
    width: 78px;
    min-width: 78px;
    max-width: 78px;
    border-top-left-radius: 11px;
}

.colhead {
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    font-family: inherit;
    color: #fff;
    overflow-y: hidden;
    display: block;
}

.rowhead {
    text-align: left;
    font-size: 13px;
    font-family: inherit;
    color: #fff;
    overflow-y: hidden;
    display: block;
}

.cpmeta_colL {
    height: 39px;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    background: linear-gradient(180deg, rgb(3, 19, 13), rgba(4, 38, 28, 0.885));
}
.cpmetaR {
    padding-right: 2px;
    width: 78px;
    min-width: 78px;
    max-width: 78px;
    height: 39px;
    background-repeat: repeat-x;
    background: linear-gradient(180deg, rgb(3, 19, 13), rgba(4, 38, 28, 0.885));
    border-top-right-radius: 11px;
}
td.cpvless {
    padding-left: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-color: rgba(4, 38, 28, 0.529);
}

td
    #one
    #five
    #seven
    #nine
    #elleven
    #twentyseven
    #twentynine
    #thirtyone
    #thirtythree
    #thirtyfive
    #thirtyseven
    #thirtynine
    #fourtyone
    #fourtythree
    #fourtyfive
    #fourtyseven
    .cpvless {
    padding-left: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-width: 1px;
    border-spacing: 0px;
    padding: 0px;
    cursor: pointer;
}
.cpvd {
    padding-right: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-left-width: 0px;
    background-color: #fff;
}

table,
td
    #two
    #four
    #six
    #eight
    #ten
    #twelve
    #fourteen
    #fifthteen
    #seventeen
    #eightteen
    #nineteen
    #twenty
    #twentyone
    #twentythree
    #twentyfour
    #twentysix
    #twentyeight
    #thirty
    #thirtytwo
    #thirtyfour
    #thirtysix
    #thirtyeight
    #fourty
    #fourtytwo
    #fourtyfour
    #fourtysix
    #fourtyeight
    #fiftyone
    #fiftythree
    #fiftyfour
    #fiftyfive
    #fiftyseven
    #fiftyeight
    #fiftynine
    .cpvd {
    padding-right: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-left-width: 0px;
    background-color: #fff;
    padding: 0px;
    border-spacing: 0px;
    border-width: 1px;
    border-spacing: 0px;
    padding: 0px;
    cursor: pointer;
}
.cpvdnone {
    padding-right: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-left-width: 0px;
    background-color: #fff;
}

td #four #sixteen #twentytwo #twentyfive #fifty #fiftytwo #fiftysix .cpvdnone {
    padding-right: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-left-width: 0px;
    background-color: #fff;
    padding: 0px;
    border-spacing: 0px;
    border-collapse: 0px;
    border-width: 1px;
    border-spacing: 0px;
    padding: 0px;
}

.cpvlessnone {
    padding-left: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-left-width: 0px;
}

td,
#three #fourtynine .cpvlessnone {
    padding-left: 6px;
    height: 39px;
    width: 34px;
    text-align: center;
    border-right-width: 0px;
    border-left-width: 0px;
    padding: 0px;
    border-spacing: 0px;
    border-width: 1px;
    border-spacing: 0px;
    padding: 0px;
    border-color: rgba(4, 38, 28, 0.529);
}

.symS {
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: rgba(50, 50, 50, 1);
    font-size: 15px;
}

.cpvlessblank {
    background-color: rgba(5, 60, 36, 0.33);
    width: 40px;
    border-right-width: 0px;
    height: 39px;
}
.cpvdblank {
    background-color: rgba(5, 60, 36, 0.33);
    width: 40px;
    border-left-width: 0px;
    height: 39px;
}

td #thirteen .cpall {
    height: 39px;
    width: 38px;
    text-align: center;
}

.cpall {
    height: 39px;
    width: 38px;
    text-align: center;
}

.cpallblank {
    background-color: rgba(5, 60, 36, 0.33);
    height: 39px;
    width: 38px;
}
